.uk-pc-carousel-small {
  width: 1264px;
  height: 280px;
  margin-left: auto;
  margin-right: auto;

  .mySwiper {
    --swiper-navigation-size: 15px;
    --swiper-navigation-color: white;
    --swiper-navigation-sides-offset: 6px;
    --swiper-pagination-color: white;
    --swiper-pagination-bullet-width: 6px;
    --swiper-pagination-bullet-height: 6px;
    --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 0.8);
    --swiper-pagination-bullet-inactive-opacity: 0.4;
    --swiper-navigation-color: white;
    --swiper-navigation-hover-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;

    .slideItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      img:hover {
        filter: brightness(105%);
      }
    }
    .buttonGroup {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 999;
      pointer-events: none;

      .prev {
        background-color: #0000001f;
        padding-right: 10px;
        width: 40px;
        height: 48px;
        border-radius: 0 24px 24px 0;
        pointer-events: auto;
      }
      .next {
        background-color: #0000001f;
        padding-left: 16px;
        width: 40px;
        height: 48px;
        border-radius: 24px 0 0 24px;
        pointer-events: auto;
      }
      .prev:hover {
        background-color: #0000003d;
      }
      .next:hover {
        background-color: #0000003d;
      }
    }
  }
}
