.bg {
  width: 100%;
  height: 328px;
  padding-bottom: 24px;
  border-radius: 8px;
  background: #00000005;
}
.uk-pc-popular-categoriesV2 {
  width: 1264px;
  height: 328px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  .title {
    font-weight: bold;
    // height: 32px;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    margin-left: 24px;
    padding-top: 20px;
    display: flex;
    align-items: center;
  }

  .mySwiper {
    width: 100%;
    padding: 0 25px;
    .slideItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        border-radius: 4px;
        width: 180px;
        height: 180px;
        object-fit: cover;
      }

      p {
        margin-top: 12px;
        text-align: center;
      }
    }
    .slideItem:hover {
      img {
        filter: brightness(105%);
      }
      p {
        color: #990915;
      }
    }
    // }

    .buttonGroup {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 30%;
      // transform: translateY(-100%);
      transform: translatex(-25px);
      z-index: 999;
      pointer-events: none;

      .prev {
        background-color: #0000001f;
        padding-right: 10px;
        width: 40px;
        height: 48px;
        border-radius: 0 24px 24px 0;
        pointer-events: auto;
      }
      .next {
        background-color: #0000001f;
        padding-left: 16px;
        width: 40px;
        height: 48px;
        border-radius: 24px 0 0 24px;
        pointer-events: auto;
      }
      .prev:hover {
        background-color: #0000003d;
      }
      .next:hover {
        background-color: #0000003d;
      }
    }
  }
}
